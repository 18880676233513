import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styles from './deleteAuthorForm.module.css';
import config from '../../../config.js';
import Notification from '../../generic/notifications/notification.js'; // Import the Notification component

export default function DeleteAuthorForm() {
  const [authors, setAuthors] = useState([]);
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [message, setMessage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const authorsPerPage = 10;
  const selectAllRef = useRef();

  useEffect(() => {
    fetchAuthors();
  }, []);

  useEffect(() => {
    if (selectAllRef.current) {
      const isIndeterminate =
        selectedAuthors.length > 0 && selectedAuthors.length < authors.length;
      selectAllRef.current.indeterminate = isIndeterminate;
    }
  }, [selectedAuthors, authors]);

  const fetchAuthors = async () => {
    try {
      const response = await axios.get(`${config.API_ENDPOINT}/authors`);
      setAuthors(response.data);
    } catch (error) {
      console.error('Error fetching authors:', error);
      setMessage({ type: 'error', text: 'Failed to fetch authors.' });
    }
  };

  const toggleAuthorSelection = (authorId) => {
    if (selectedAuthors.includes(authorId)) {
      setSelectedAuthors(selectedAuthors.filter(id => id !== authorId));
    } else {
      setSelectedAuthors([...selectedAuthors, authorId]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedAuthors(authors.map(author => author._id));
    } else {
      setSelectedAuthors([]);
    }
  };

  const handleDeleteSelectedAuthors = async () => {
    try {
      await axios.delete(`${config.API_ENDPOINT}/authors`, {
        data: { authorIds: selectedAuthors },
      });
      fetchAuthors();
      setSelectedAuthors([]);
      setShowConfirm(false);
      setMessage({ type: 'success', text: 'Selected authors have been deleted successfully.' });
    } catch (error) {
      console.error('Error deleting authors:', error);
      setMessage({ type: 'error', text: 'Failed to delete selected authors.' });
    }
  };

  // Filtering authors based on search
  const filteredAuthors = authors.filter(author =>
    author.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination calculations
  const indexOfLastAuthor = currentPage * authorsPerPage;
  const indexOfFirstAuthor = indexOfLastAuthor - authorsPerPage;
  const currentAuthors = filteredAuthors.slice(indexOfFirstAuthor, indexOfLastAuthor);
  const totalPages = Math.ceil(filteredAuthors.length / authorsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className={styles.container}>
      <h2 className={styles.titleText}>Delete Authors</h2>
      {message && (
        <div
          className={
            message.type === 'success' 
              ? styles.successMessage 
              : styles.errorMessage
          }
        >
          {message.text}
          <button onClick={() => setMessage(null)} className={styles.closeMessage}>
            &times;
          </button>
        </div>
      )}
      <input
        type="text"
        placeholder="Search by name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className={styles.searchInput}
      />
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.titleText}>
              <th>
                <input
                  type="checkbox"
                  ref={selectAllRef}
                  onChange={handleSelectAll}
                  checked={selectedAuthors.length === authors.length}
                />
              </th>
              <th>Name</th>
              <th>Number of Books</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentAuthors.map(author => (
              <tr key={author._id} className={styles.textRow}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedAuthors.includes(author._id)}
                    onChange={() => toggleAuthorSelection(author._id)}
                  />
                </td>
                <td>{author.name}</td>
                <td>{author.booksCount}</td>
                <td>
                  <button onClick={() => {/* Implement view details */}} className={styles.viewButton}>
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button
        type="button"
        onClick={() => setShowConfirm(true)}
        disabled={selectedAuthors.length === 0}
        className={styles.deleteButton}
      >
        Delete Selected Authors
      </button>
      <div className={styles.pagination}>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => paginate(index + 1)}
            className={`${styles.pageButton} ${currentPage === index + 1 ? styles.activePage : ''}`}
          >
            {index + 1}
          </button>
        ))}
      </div>
      {showConfirm && (
        <div className={styles.modalOverlay}>
          <div className={styles.modal}>
            <h3>Confirm Deletion</h3>
            <p>Are you sure you want to delete the selected authors?</p>
            <div className={styles.modalButtons}>
              <button onClick={handleDeleteSelectedAuthors} className={styles.confirmButton}>
                Yes, Delete
              </button>
              <button onClick={() => setShowConfirm(false)} className={styles.cancelButton}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}


