import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import styles from './searchAuthorForm.module.css';
import config from '../../../config.js';

export default function SearchAuthorForm() {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const popupRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      if (searchQuery === '') {
        setSearchResults([]); // Clear results if search query is empty
        return;
      }

      try {
        // Fetch data from the backend using the search query
        const response = await axios.get(`${config.API_ENDPOINT}/authors/search?q=${searchQuery}`);
        setSearchResults(response.data); // Update search results state
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [searchQuery]); // Trigger useEffect when searchQuery changes

  useEffect(() => {
    // Add event listener to detect clicks on the document
    const handleClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setSearchResults([]); // Close the pop-up when clicking outside
      }
    };

    document.addEventListener('click', handleClick);

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <div className={styles.searchImg}>
      <div className={styles.autocompleteContainer}>
        <input
          type="text"
          placeholder="Search by author name..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        {searchResults.length > 0 && (
          <div className={styles.autocompletePopup} ref={popupRef}>
            {searchResults.map((author) => (
              <div key={author._id}>
                <a href={`/author/${author._id}`} target="_blank" rel="noopener noreferrer">
                  {author.name}
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}